<template>
  <div class="apply-page">
    <el-dialog
  title="审核结果"
  top="30vh"
  :visible.sync="rejectshow" 
  width="500px"
  height="400px"
  :before-close="handleClose"
  center>
  <div class="reject" v-if="state==2">
    <span>审核未通过：</span>{{this.reason}}
  </div>
  <div class="reject" v-if="state==1" style="text-align:center">
    审核已通过！
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="rejectshow=false">关闭</el-button>
  </span>
</el-dialog>
<!-- 弹出框 -->
    <topbar2></topbar2>
  <div class="body-bg">
<div class="center-body">
        <div class="settop">
          <span class="first">1</span>
          <p class="first_text">填写供应商加盟资料</p>
          <p class="preline"></p>
          <span class="second">2</span>
          <p class="second_text">完成</p>
        </div>
        <!-- <div class="reject" v-if="state==2">
          <h2>拒绝理由:</h2>
          <p><span>{{this.reason}}</span></p>
        </div> -->
        <div class="store_message" v-if="showinfo">
            <h2>店铺信息</h2>
          <p><label class="lab"><i class="required">*</i>店铺名称</label>
          <span>{{this.$store.state.user.store_name}}</span></p>
        </div>
        <div ref="id_con" class="firm_message" >
            <h2>公司信息</h2>
          <p><label class="lab">&nbsp;&nbsp;<i class="required2">*</i>公司名称</label>
          <input class="input_text" type="text"  placeholder="请输入公司名称" @keyup="ablestep()" v-model="Corporate_name" /></p>
          <p><label class="lab">&nbsp;&nbsp;<i class="required2">*</i>所属省份</label>
          <v-distpicker  data-autoselect="2"  hide-area :province="province" :city="city" @province="onChangeProvince" @city="onChangeCity" ></v-distpicker></p>
          <p><i class="zhanwei"></i> <label class="lab">法人代表</label>
          <input class="input_text" type="text"  placeholder="请输入法人代表" v-model="representative" /></p>
          <p><label class="lab" id="c_add">公司注册地址</label>
          <input class="input_text" id="input_add" type="text" min="30"  placeholder="请输入公司注册地址" v-model="Company_address" /></p>
        </div>
        <div class="firm_message">
            <h2>联系信息</h2>
          <p><label class="lab3">&nbsp;&nbsp;<i class="required2">*</i>联系人</label>
          <input class="input_text" type="text"  placeholder="请输入联系人" @keyup="ablestep()"  v-model="Contacts" /></p>
          <p><label class="lab3">&nbsp;&nbsp;<i class="required2" >*</i>联系人邮箱</label>
          <input class="input_text" type="text"  placeholder="请输入邮箱" v-model="Contacts_email" /></p>
          <p><label class="lab3">&nbsp;&nbsp;<i class="required2" >*</i>手机号码</label>
          <input class="input_text" type="text"  placeholder="请输入手机号码" @keyup="ablestep()" v-model="phone" maxlength="11"/></p>
        </div>
        <div class="firm_message">
            <h2>接口信息</h2>
            <br>
          <p><label class="lab3"><i class="required2" >*</i>商品接口</label>
          <input class="input_text" type="text"  placeholder="请输入商品接口"  v-model="goods_url" @keyup="ablestep()" /></p>
          <p><label class="lab3"><i class="required2" >*</i>下单接口</label>
          <input class="input_text" type="text"  placeholder="请输入下单接口" v-model="place_url" @keyup="ablestep()" /></p>
          
        </div>
        <div class="submit" v-if="show">
          <span  type="danger" size="large" class="nextbtn" @click="step()">下一步</span>
        </div>
        <div class="submit" v-else>
          <span  type="danger" size="large" class="btn">下一步</span>
        </div>
</div>
  </div>
<!-- 导航栏 -->
</div>
</template>

<script>
import VDistpicker from 'v-distpicker';
import Topbar2 from '@/components/topbar2'
export default {
  name: "suppliers",
  // inject:['app'],
  components: { 
  VDistpicker,
  Topbar2
  },
  data() {
    return {
      showinfo: true,
      nickname:"昵称",
      Corporate_name:"",
      // 公司名称
      province:"",
      //公司所属省份
      city:"",
      //公司所属城市
      addre:"",
      //公司所属省市
      Contacts:"",
      //联系人名称
      phone:"",
      //联系人手机号
      representative:"",
      //法人代表
      Company_address:"",
      //公司地址
      Contacts_email:"",
      //联系人邮箱
      category:"",
      //加盟类目
      user_type:"",
      //用户状态
      show:false,
      state:'',//审核状态
      rejectshow:false,
      checkinfo:'',
      reason:'',//拒绝理由
      goods_url:'',//商品接口
      place_url:'',//下单接口
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    this.user_type=this.$store.state.user.user_type;
        if(this.user_type==0||this.user_type==1||this.user_type==2||this.user_type==3){
          this.showinfo=true;
            this.$refs.id_con.style.margin = '60px auto 0px auto';
        }else{
          this.showinfo=false;
          this.$refs.id_con.style.margin='60px auto 0px auto'
        }
  },
  activated(){
    this.getstaus();
    if(this.state==2||this.state==1||this.state==0){
    this.Corporate_name=this.checkinfo.Corporate_name;
    this.representative=this.checkinfo.representative;
    this.Company_address=this.checkinfo.Company_address;
    this.Contacts=this.checkinfo.Contacts;
    this.Contacts_email=this.checkinfo.Contacts_email;
    this.phone=this.checkinfo.phone;
    this.reason=this.checkinfo.reason_s;
    this.place_url=this.checkinfo.place_url;
    this.goods_url=this.checkinfo.goods_url;
    this.addre=this.checkinfo.Province.split('|') ;
    this.province=this.addre[0]
    this.city=this.addre[1]
    this.rejectshow=true;
    this.ablestep();
  }
  },
  methods: {
    handleClose(done) {
            done();
      },
      //弹出框关闭
    onChangeProvince(data){ 
        this.province=data.value;            
            },
    onChangeCity(data){ 
        this.city=data.value;
        this.addre=this.province+"|"+this.city;
          },
    getstaus(){
        var that=this;
        let params={'user_id':this.$store.state.user.vir_id,'p_id':this.$store.state.user.p_id};
        $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/supplier_apply_status',
          data:params,
          dataType:'json',
          success:function(res){
            if(res.data==400){
              that.state=-1
            }else{
              that.checkinfo=res.data[0]
              that.state=that.checkinfo.status;
            }
        }         
            })
      },
    ablestep(){
      this.addre=this.province+"|"+this.city;
      if(this.Corporate_name!=""&&this.addre!=""&&this.Contacts!=""&&this.phone.toString().length==11&&this.goods_url!=''&&this.place_url!=''){
        this.show=true;
      }else{
        this.show=false;
      }
    },
    step() {
      var that=this;
      let params={'Corporate_name':this.Corporate_name,'Province':this.addre,'Contacts':this.Contacts,
      'phone':this.phone,'representative':this.representative,'Company_address':this.Company_address,'goods_url':this.goods_url,'place_url':this.place_url,
      'Contacts_email':this.Contacts_email,'Receiving_address':'','category':'1','user_id':this.$store.state.user.vir_id,'p_id':this.$store.state.user.p_id,'supplier_id':this.$store.state.user.m_id}
      $.ajax({
          async:false,
          type:'post',
          url:this.$store.state.user.q_url+'/api/api/Supplier',
          data:params,
          dataType:'json',
          success:function(res){
           if(res.success){
              that.$router.push("./apply_complated");
            }else {that.$message({
          message: res.message,
          type:"warning",
          center:'true',
        });
            }
        },
               
        })
    }
  }
};
</script>

<style lang="less" scoped>
.apply-page {
  position: relative;
  padding: 0px;
  margin: 0;
  width: 100%;
  height: 50px;
  //导航栏
.body-bg{width:100%;
position: absolute;
top: 80px;
padding-top: 20px;
background:rgba(242,243,250,1);}
.reject{width: 100%;font-size: 16px;font-family: Microsoft YaHei;font-weight: 400;letter-spacing:1px;text-indent: 30px;}
.center-body{width:1300px;
background:rgba(255,255,255,1);
border:1px solid rgba(222,222,222,1);
border-radius:8px;
margin: 0 auto;
box-sizing: border-box;
overflow: hidden;
position: relative;
.settop {
      height: 40px;
      width: 680px;
      margin: 100px auto 40px auto;
      display: block;
      > span {
        display: inline-block;
      }
      > p {
        display: inline-block;
      }
      .first {
        width: 36px;
        height: 36px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border: 2px solid rgba(222, 222, 222, 1);
        border-radius: 50%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(61, 49, 226, 1);
        line-height: 32px;
        margin-left: 18px;
      }
      .first_text {
        height: 20px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(55, 67, 214, 1);
        line-height: 26px;
        margin: 0;
        margin-left: 18px;
      }
      .second {
        width: 36px;
        height: 36px;
        text-align: center;
        background: rgba(204, 204, 204, 1);
        border-radius: 50%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 36px;
        margin-left: 18px;
      }
      .second_text {
        height: 20px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 26px;
        margin: 0;
        margin-left: 18px;
      }
      .preline {
        width: 200px;
        height: 4px;
        background: rgba(204, 204, 204, 1);
        border-radius: 1px;
        margin-left: 18px;
        margin-bottom: 5px;
      }
    }
  .store_message{
      width: 500px;
      margin:60px auto;
      .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
      .required{margin-right: 2px;color: #F42C2E;font-style: normal;}
      .required2{margin-right: 2px;color: #F42C2E;font-style: normal;padding-left: 15px;display: inline-block;}
    }
    >p span{margin-left: 10px;display: inline-block;
font-size:16px;
font-family:Microsoft YaHei;
font-weight:400;
color:rgba(51,51,51,1);
line-height:26px;}
  }
  .input_text {
      outline: none;
      text-indent: 10px;
      padding-left: 10px;
      font-size: 16px;
      font-weight: 400;
      z-index: 10;
      width:280px;
height:38px;
background:rgba(255,255,255,1);
border:2px solid rgba(238,238,238,1);
margin-left: 20px;
    }
    .firm_message{
        width: 500px;
        margin: 60px auto 0px auto;
        .lab {
      width: 51px;
      height: 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
      .required{margin-right: 2px;color: #F42C2E;font-style: normal;}
      .required2{margin-right: 2px;color: #F42C2E;font-style: normal;padding-left: 15px;display: inline-block;}
    }
    .lab3 {
      width: 110px;
      height: 30px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 33px;
      display: inline-block;
      text-align: right;
      .required{margin-right: 2px;color: #F42C2E;font-style: normal;}
      .required2{margin-right: 2px;color: #F42C2E;font-style: normal;padding-left: 15px;display: inline-block;}
    }
    #c_add{position: relative;right: 10px;}
    #input_add{margin-left: 10px}
    >p{margin: 20px 0 0 0;height: 40px;display: inline-block;
    .zhanwei{margin-left: 22px;}}
}
    .firm_message /deep/ .distpicker-address-wrapper{display: inline-block;margin-left: 20px;width: 280px;box-sizing:border-box;height: 40px;
    }
    .firm_message /deep/ select {
  width: 138px;
  padding: 0;
  font-size: 18px;
  outline: none;
  border-radius: 0;
  >option{text-align: center;display: inline-block;width: 140px;}}
    .submit {
      height: 60px;
      display: block;
      margin: 100px auto 80px auto;
      width: 366px;
    }
    .submit .nextbtn {
      cursor: pointer;
      width: 360px;
      height: 60px;
      background: rgba(55, 67, 214, 1);
      border-radius: 30px;
      display: block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;
    }
    .btn{width: 360px;
    cursor: pointer;
      height: 60px;
      background:rgba(153,153,153,1);
      border-radius: 30px;
      display: block;
      font-size: 22px;
      font-family:Microsoft YaHei;
      font-weight:400;
      color:rgba(255,255,255,.7);
      line-height: 60px;
      text-align: center;}
}
}
</style>