<template>
  <div class="topbar">
    <div class="logo">
     <img src="@/assets/logo.png" @click="toindex()" title="返回首页"/>
    </div>
    <!-- 导航栏 -->
<div class="right_nav">
  <!-- <img src="@/assets/nav_message_b.svg" />
<span>站内信</span> -->
<em class="uname" v-if="this.$store.state.user.store_name=='店铺'">{{this.$store.state.user.phone}}</em>
  <em class="uname" v-else>{{this.$store.state.user.store_name}}</em>
&nbsp;<span>|</span>&nbsp;<span class="quit" @click="quit()">退出</span>
<!-- 导航栏 -->
</div>
  </div>
</template>

<script>
export default {
  name: "topbar2",
  // inject:['app'],
  data() {
    return {
      user: "",
      islogin: false,
      nickname:"昵称"
    };
  },
  mounted() {
    this.$parent.$parent.$parent.sskj = false;
    this.nickname=this.$store.state.user.user;
  },
  methods: {
    toindex() {
      this.$router.push('./homepage');
    },
    quit(){
      this.$router.push('./Login');
    },
    
  }
};
</script>

<style lang="less" scoped>
.topbar{width: 100%;position: relative;height: 75px;min-width: 1540px;
  //导航栏
.right_nav{height: 36px;line-height: 36px;position: absolute;width: 240px;z-index: 5000;right: 280px;margin-top:27px;
>img{width: 24px;height: 24px;position: relative;top: 6px;}
>span{font-size:14px;
font-family:Microsoft YaHei;
font-weight:400;
color:#000;
display: inline-block;
position: relative;
left: 6px;}
>em{font-size:14px;
font-style: normal;
font-family:Microsoft YaHei;
color:#000;
display: inline-block;
height: 36px;
text-align: center;
margin-left: 20px;
text-align: right;
}
}
    .logo {
  width: 60px;
  height: 60px;
  position: absolute;
  left: 360px;
  top: 14px;
  z-index: 5000;
  > img {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
.quit{cursor: pointer;}
}


</style>