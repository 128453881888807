<template>
    <suppliers/>
</template>

<script>
// @ is an alias to /src
import suppliers from "@/components/suppliers";
export default {
  name: "Suppliers",
  components: {
    suppliers,
  }
};
</script>